import { Injectable } from "@angular/core";
import {
    HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaders,
    HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/internal/operators/catchError";
import { finalize } from "rxjs/internal/operators/finalize";
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LoadingService } from "app/config/http/loading.service";


@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    constructor( private router: Router, private loadingService: LoadingService ) { }

    intercept( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
		let headers: HttpHeaders = request.headers;
        const url = this.getIntercepted( request.url );
		if (headers.get('disabledLoading') !== "true" && url.indexOf("/lang_") == -1) {
			this.loadingService.showLoading();
		}
        const token = sessionStorage.getItem( 'token' );
        let httpHeaders;
        if ( token != undefined && token != null ) {
            httpHeaders = new HttpHeaders( { 'Authorization': token } );
        }
        const customRequest = request.clone( {
            headers: httpHeaders,
            reportProgress: request.reportProgress,
            params: request.params,
            responseType: request.responseType,
            withCredentials: true,
            body: request.body,
            method: request.method,
            url: url
        } );
        return next.handle( customRequest ).pipe(
            catchError(( error, caught ) => {
                return throwError( error );
            } ),
            finalize(() => {
				if (headers['disabledLoading'] !== "true" && url.indexOf("/lang_") == -1) {
					this.loadingService.hideLoading();
				}
            } ) ) as any;
    }

    getIntercepted( url: string ) {
        if ( !url.startsWith( 'http' ) && !url.startsWith( './assets' ) ) {
            return environment.contextPath + url;
        }
        return url;
    }

}
