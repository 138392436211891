export class AppEnum {

	public static readonly GENERAL_MEDICINE: any = { id: 1, prefix: "doc", name: "general-medicine", code: "general" };
	public static readonly GYNECO: any = { id: 2, prefix: "gyn", name: "gyneco", code: "gyneco" };
	public static readonly PSYCHOLOGY: any = { id: 3, prefix: "psy", name: "general-medicine", code: "psy" };

    public static getAppList() {
        return [AppEnum.GENERAL_MEDICINE, AppEnum.GYNECO, AppEnum.PSYCHOLOGY];
    }

}
